<template>
  <div class="shopping">
    <Header></Header>
    <Nav></Nav>
    <div class="crumb-box">
      <div class="contaner">
        <el-row class="nav-box" style="height: 81px" type="flex" align="middle">
          <!-- <span class="pos" style="opacity: 0">您现在的位置：</span> -->
          <!-- <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>机床商城</el-breadcrumb-item>
            <el-breadcrumb-item>商品列表</el-breadcrumb-item>
          </el-breadcrumb> -->
          <!-- <span class="pos">您现在的位置：</span> -->
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ name: 'home' }">
              <span class="c9">首页</span>
            </el-breadcrumb-item>
            <el-breadcrumb-item :to="{ name: 'shopping' }"
              ><span class="c3" style="font-weight: 400"
                >机床商城</span
              ></el-breadcrumb-item
            >
            <el-breadcrumb-item
              ><span class="c3">结算</span></el-breadcrumb-item
            >
          </el-breadcrumb>
        </el-row>
      </div>
    </div>
    <div class="content-box">
      <div class="contaner">
        <div class="mark-settle-box">
          <div class="head head1">
            <p>收货地址</p>
            <div class="ad">
              <div>+</div>
              <p>新增收货地址</p>
            </div>
          </div>
          <div class="has-add">
            <div class="has">
              <p class="l1">
                <span class="s1">公司</span>
                <span>蔡菜菜(先生)</span>
              </p>
              <p class="l2">
                河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市
                二七区河南省 郑州市 二七区 大学路80号华城国际中心19层
                150****2232
              </p>
              <p class="l3">默认地址</p>
            </div>
            <div class="has has1">
              <p class="l1">
                <span class="s1">公司</span>
                <span>蔡菜菜(先生)</span>
              </p>
              <p class="l2">
                河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市
                二七区河南省 郑州市 二七区 大学路80号华城国际中心19层
                150****2232
              </p>
              <div class="l3-b">
                <div>
                  <span>编辑</span>
                  <span class="line3">|</span>
                  <span>删除</span>
                </div>
                <p class="l3">设为默认地址</p>
              </div>
            </div>
            <div class="has">
              <p class="l1">
                <span class="s1">公司</span>
                <span>蔡菜菜(先生)</span>
              </p>
              <p class="l2">
                河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市
                二七区河南省 郑州市 二七区 大学路80号华城国际中心19层
                150****2232
              </p>
              <p class="l3">设为默认地址</p>
            </div>
          </div>
          <div class="no-add">
            <p>您还没有自提点信息，你可以<span>新增收货地址</span></p>
            <img src="@/assets/images/noadd.png" alt="" />
          </div>
          <div class="head">确认订单信息</div>
          <div class="order-info">
            <div class="hea">
              <div class="h">配件图片</div>
              <div class="h hb">商品信息</div>
              <div class="h">单价</div>
              <div class="h">数量</div>
              <div class="h">小计</div>
            </div>
            <div class="table">
              <div class="h">
                <img src="@/assets/images/noadd.png" alt="" />
              </div>
              <div class="h hb">
                <p class="li1">
                  【2023新品英特尔酷睿i7笔记本】轻薄便携笔记本电脑15.6英寸办公【2023新品英特尔酷睿i7笔记本】轻薄便携笔记本电脑15.6英寸办公【2023新品英特尔酷睿i7笔记本】轻薄便携笔记本电脑15.6英寸办公
                </p>
                <p class="li2">这是配件品牌</p>
              </div>
              <div class="h">￥0.9620</div>
              <div class="h">100.00</div>
              <div class="h red">￥96.2000</div>
            </div>
            <div class="remark">
              <p>备注</p>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="建议留言前与先沟通确认"
                v-model="textarea"
              >
              </el-input>
            </div>
            <div class="msg">
              <p>
                2件商品 <span class="m1">商品金额：</span
                ><span class="m2">￥207.40 </span>
              </p>
            </div>
            <div class="btn">
              <div class="b1">
                <router-link :to="{ name: 'marketaddorder', query: { id: id } }"
                  >提交订单</router-link
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 32px; background: #f8f8f8"></div>
    <Footer></Footer>
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";
import Header from "@/components/home/Header.vue";
import Nav from "@/components/home/Nav.vue";
import Footer from "@/components/home/Footer.vue";
export default {
  components: {
    Layout,
    Header,
    Nav,
    Footer,
  },
  data() {
    return {
      // 选择条数数据
      value: "5",
      pageSizeOptions: [
        {
          value: "5",
          label: "5",
        },
        {
          value: "10",
          label: "10",
        },
        {
          value: "15",
          label: "15",
        },
      ],

      // 顶部的筛选按钮
      activeIndex: 0,

      // 价格的排序方式 0-升序 1-降序
      sortType: 1,

      // 表格数据
      tableData: [],
      goodType: [],
      typeId: "", //分类对应id
      total: 0, //列表总数
      page: 1,
      pageSize: 5,
      size: 5,
      // pageSizeArr: [5, 10, 15],
    };
  },
  created() {
    // 二手商品-查询机床类目
    this.getGoodSort();
    // 二手商品列表
    this.getGoodList();
  },
  methods: {
    // 二手商品列表
    getGoodList() {
      // // 价格的排序方式 0-升序 1-降序
      let categoryOneId = this.typeId ? Number(this.typeId) : "";
      let paramData = {
        pageNo: this.page,
        pageSize: this.pageSize,
        isAsc: this.sortType == 1 ? false : true, //是否升序  true升序   false降序
        categoryOneId, //分类id
      };
      this.$API
        .getGoodList(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.total = Number(res.data.total);
            this.tableData = [];
            this.tableData = res.data.records;
            window.scrollTo(0, 0);
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 类目改变
    changeType() {
      // console.log(this.typeId);
      this.getGoodList();
    },
    // 二手商品-查询机床类目
    getGoodSort() {
      let paramData = {
        categoryType: 2,
        parentId: 0,
      };
      this.$API
        .getGoodSort(paramData)
        .then((res) => {
          if (res.code == 1) {
            this.goodType = res.data;
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 顶部的筛选按钮点击操作
    handleChange(index) {
      // if (index === this.activeIndex && index === 1) {
      //   this.sortType = this.sortType === 0 ? 1 : 0;
      //   return;
      // }
      this.sortType = this.sortType === 0 ? 1 : 0;
      this.activeIndex = index;
      this.getGoodList();
    },

    // 每页展示条数改变操作
    handleSizeChange(size) {
      // 每页条数改变
      // console.log(size);
      // console.log("改变了", size);
      this.pageSize = Number(size);
      this.size = Number(size);
      this.getGoodList();
    },

    // 页码改变操作
    handleCurrentChange(page) {
      this.page = page;
      this.getGoodList();
      // console.log(page, "页码改变");
    },
  },
};
</script>

<style lang="scss">
.el-select {
  width: 90px;
}
.el-pagination .el-input__suffix {
  right: 22px;
  top: 5px;
  transform: scale(0.8);
}
.el-pagination .el-select .el-input .el-input__inner {
  width: 80px !important;
  height: 30px !important;
}
.shopping .el-table th.el-table__cell > .cell {
  color: #000;
}
.el-input__inner:focus {
  color: #333333 !important;
}
.type-select {
  margin-left: 20px;
  width: 200px;
  height: 40px;
  color: #333333;
  .el-select {
    width: 200px;
  }
}
/* 全局样式 */
/* 重置单元格样式 */
.el-table.shop-list-table td.el-table__cell .cell {
  padding-left: 0;
  padding-right: 0;
}
.el-table.shop-list-table .el-table__body-wrapper .el-table__row:hover td {
  background-color: #fff;
}
.price-column-style {
  vertical-align: top !important;
}
/* 重置element-ui分页的激活页码颜色 */
.shop-list-pagination.el-pagination.is-background
  .el-pager
  li:not(.disabled):hover {
  color: #ed1b23;
}
.shop-list-pagination.el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #ed1b23;
  color: #fff;
}

/* 重置分页的样式 */
.shop-list-pagination
  .el-pagination__sizes
  .el-input
  .el-input__inner.el-input__inner:hover {
  border-color: #ed1b23;
}
.shop-list-poper-class
  .el-select-dropdown__wrap
  .el-select-dropdown__item.selected {
  color: #ed1b23;
}

/* 重置跳转页码的样式 */
.shop-list-pagination .el-input.is-active .el-input__inner,
.el-input__inner:focus {
  color: #ed1b23;
  border-color: #ed1b23;
}
</style>
<style scoped lang="scss">
.text-ellipse {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
.disabled-text-select {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}

/* 顶部面包屑导航 */
.crumb-box {
  height: 81px;
  line-height: 81px;
  background-color: #f8f8f8;
  border-top: 1px solid #ed1b23;
}

.content-box {
  background-color: #f8f8f8;
  .contaner {
    padding: 24px 32px;
    padding-bottom: 0;
    background-color: #fff;

    // 顶部的筛选按钮
    .top-box {
      display: flex;
      .btn-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        height: 36px;
        border-radius: 6px 6px 6px 6px;
        text-align: center;
        line-height: 36px;
        font-size: 16px;
        font-weight: 400;
        color: #333;
        background-color: #f5f5f5;
        cursor: pointer;

        .btn-item-icon {
          margin-left: 8px;
          display: flex;
          flex-direction: column;

          .el-icon-caret-bottom {
            margin-top: -8px;
            color: #bfbfbf;
          }

          i.active {
            color: #333;
          }
        }

        &.active {
          background: #ed1b23;
          color: #fff;

          i {
            color: #bfbfbf;
          }

          i.active {
            color: #fff;
          }
        }
      }
    }

    // 列表 - element-ui表格版本
    .table-box {
      border: 0 none;
      .left {
        //   width: 70%;
        width: 600px;
        display: flex;

        .shop-img {
          width: 160px;
          height: 160px;
        }

        .shop-info {
          // align-self: flex-start;
          margin-left: 20px;
          // padding-top: 8rpx;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
        }
      }

      .right {
        .shop-price {
          text-align: center;
          padding-top: 8px;
          color: #ed1b23;
          font-size: 20px;
          font-weight: bold;

          .text1 {
            font-size: 14px;
          }
          .text2 {
            padding-left: 4px;
          }
        }
      }
    }

    // 自定义列表版本样式
    // // 表格区域
    // .table-box {
    //   border: 0 none;

    //   .table-row {
    //     display: flex;
    //     justify-content: space-between;
    //     padding: 20px 0;
    //     border-bottom: 1px solid #eeeeee;

    //     &.table-head {
    //       font-size: 20px;
    //       font-weight: 400;
    //       color: #000000;
    //       line-height: 46px;
    //       text-align: center;

    //       .left {
    //         display: block !important;
    //       }
    //     }

    //     .left {
    //       //   width: 70%;
    //       width: 600px;
    //       display: flex;

    //       .shop-img {
    //         width: 160px;
    //         height: 160px;
    //       }

    //       .shop-info {
    //         align-self: flex-start;
    //         margin-left: 20px;
    //         padding-top: 8rpx;
    //         font-size: 16px;
    //         font-weight: bold;
    //         color: #333333;
    //         line-height: 25px;
    //       }
    //     }

    //     .right {
    //       width: 20%;

    //       .shop-price {
    //         text-align: center;
    //         padding-top: 8px;
    //         color: #ed1b23;
    //         font-size: 20px;
    //         font-weight: bold;

    //         .text1 {
    //           font-size: 14px;
    //         }
    //         .text2 {
    //           padding-left: 4px;
    //         }
    //       }
    //     }
    //   }
    // }
  }
}

//  底部分页
// .el-select {
//   width: 80px !important;
//   height: 30px !important;
// }

// .el-pagination .el-select .el-input .el-input--suffix {
//   width: 80px !important;
//   height: 30px !important;
// }

// .el-pagination .el-input {
//   width: 80px !important;
//   height: 30px !important;
// }
.pagination-box {
  .select-size {
    display: inline-block;
    margin-top: -1px;
    color: #333;
    font-weight: 400 !important;
  }
  padding-top: 40px;
  padding-bottom: 87px;
  background-color: #f8f8f8;
  .contaner {
    display: flex;
    justify-content: flex-end;
  }
  .total-num {
    margin-top: 7px;
    margin-right: 15px;
  }
}

.mark-settle-box {
  padding-bottom: 32px;

  .head {
    width: 1152px;
    height: 40px;
    background: #fff4f4;
    font-weight: 600;
    font-size: 16px;
    color: #0a0a0a;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    align-items: center;
  }

  .head1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;

    .ad {
      display: flex;
      align-items: center;
      color: #ed1b23;
      font-weight: 400;

      div {
        width: 14px;
        height: 14px;
        display: inline-block;
        background: #ed1b23;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        position: relative;
        left: -10px;
        top: 2px;
      }
    }
  }

  .has-add {
    display: flex;
    height: 232px;
    padding: 24px 0;
    padding-left: 24px;

    .has {
      margin-right: 20px;
      cursor: pointer;
      width: 360px;
      height: 164px;
      background: #f7f7f7;
      border: 1px dashed #a5a5a5;
      box-sizing: border-box;
      padding: 24px;
      color: #666666;

      .l1 {
        font-weight: 600;
        font-size: 16px;
        color: #333333;
        margin-bottom: 16px;
        .s1 {
          width: 40px;
          height: 22px;
          background: rgba(255, 255, 255, 0.1);
          border-radius: 4px 4px 4px 4px;
          border: 1px solid #bfbfbf;
          margin-right: 8px;
          font-weight: 400;
          font-size: 12px;
          color: #666666;
          padding: 2px;
        }
      }

      .l2 {
        width: 300px;
        flex-wrap: wrap;
        color: #333333;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2; /* 控制显示的行数 */
        max-height: 3em; /* 行高 x 行数 */
        margin-bottom: 24px;
      }

      .l3 {
        text-align: right;
      }

      .l3-b {
        color: #ed1b23;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .line3 {
          padding: 0 5px;
          position: relative;
          top: -2px;
        }
      }
    }

    .has1 {
      background: #fff5f6;
    }
  }

  .no-add {
    height: 314px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    p {
      font-size: 14px;
      color: #333333;
      margin-bottom: 32px;
      span {
        color: #0066ff;
      }
    }

    img {
      width: 148px;
      height: 102px;
    }
  }

  .order-info {
    .hea {
      width: 100%;
      height: 47px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333333;

      .h {
        width: 120px;
        display: flex;
        justify-content: center;
      }

      .hb {
        flex: 1;
      }
    }

    .table {
      width: 100%;
      height: 47px;
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #333333;
      height: 118px;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      margin-bottom: 49px;

      img {
        width: 86px;
        height: 86px;
      }

      .h {
        width: 120px;
        display: flex;
        justify-content: center;
      }

      .hb {
        height: 86px;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;

        .li1 {
          width: 600px;
          flex-wrap: wrap;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2; /* 控制显示的行数 */
          max-height: 3em; /* 行高 x 行数 */
        }

        .li2 {
          width: 600px;
          overflow: hidden;
          text-overflow: ellipsis;
          flex-wrap: nowrap;
        }
      }

      .red {
        color: #ff1327;
      }
    }

    .remark {
      display: flex;
      margin-bottom: 28px;
      p {
        width: 50px;
        flex-wrap: nowrap;
      }
    }

    .msg {
      text-align: right;
      color: #333333;
      margin-bottom: 32px;

      .m1 {
        margin-left: 16px;
      }
      .m2 {
        color: #fd1226;
      }
    }

    .btn {
      display: flex;
      justify-content: flex-end;

      .b1 {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        width: 155px;
        height: 46px;
        background: linear-gradient(45deg, #ed1b23 0%, #f15f64 100%);
        border-radius: 8px 8px 8px 8px;
        border: 1px solid rgba(0, 0, 0, 0);
        font-size: 16px;
        color: #ffffff;
      }
    }
  }
}
</style>
