var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shopping"},[_c('Header'),_c('Nav'),_c('div',{staticClass:"crumb-box"},[_c('div',{staticClass:"contaner"},[_c('el-row',{staticClass:"nav-box",staticStyle:{"height":"81px"},attrs:{"type":"flex","align":"middle"}},[_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[_c('el-breadcrumb-item',{attrs:{"to":{ name: 'home' }}},[_c('span',{staticClass:"c9"},[_vm._v("首页")])]),_c('el-breadcrumb-item',{attrs:{"to":{ name: 'shopping' }}},[_c('span',{staticClass:"c3",staticStyle:{"font-weight":"400"}},[_vm._v("机床商城")])]),_c('el-breadcrumb-item',[_c('span',{staticClass:"c3"},[_vm._v("结算")])])],1)],1)],1)]),_c('div',{staticClass:"content-box"},[_c('div',{staticClass:"contaner"},[_c('div',{staticClass:"mark-settle-box"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"head"},[_vm._v("确认订单信息")]),_c('div',{staticClass:"order-info"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"remark"},[_c('p',[_vm._v("备注")]),_c('el-input',{attrs:{"type":"textarea","rows":2,"placeholder":"建议留言前与先沟通确认"},model:{value:(_vm.textarea),callback:function ($$v) {_vm.textarea=$$v},expression:"textarea"}})],1),_vm._m(5),_c('div',{staticClass:"btn"},[_c('div',{staticClass:"b1"},[_c('router-link',{attrs:{"to":{ name: 'marketaddorder', query: { id: _vm.id } }}},[_vm._v("提交订单")])],1)])])])])]),_c('div',{staticStyle:{"height":"32px","background":"#f8f8f8"}}),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"head head1"},[_c('p',[_vm._v("收货地址")]),_c('div',{staticClass:"ad"},[_c('div',[_vm._v("+")]),_c('p',[_vm._v("新增收货地址")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-add"},[_c('div',{staticClass:"has"},[_c('p',{staticClass:"l1"},[_c('span',{staticClass:"s1"},[_vm._v("公司")]),_c('span',[_vm._v("蔡菜菜(先生)")])]),_c('p',{staticClass:"l2"},[_vm._v(" 河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市 二七区 大学路80号华城国际中心19层 150****2232 ")]),_c('p',{staticClass:"l3"},[_vm._v("默认地址")])]),_c('div',{staticClass:"has has1"},[_c('p',{staticClass:"l1"},[_c('span',{staticClass:"s1"},[_vm._v("公司")]),_c('span',[_vm._v("蔡菜菜(先生)")])]),_c('p',{staticClass:"l2"},[_vm._v(" 河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市 二七区 大学路80号华城国际中心19层 150****2232 ")]),_c('div',{staticClass:"l3-b"},[_c('div',[_c('span',[_vm._v("编辑")]),_c('span',{staticClass:"line3"},[_vm._v("|")]),_c('span',[_vm._v("删除")])]),_c('p',{staticClass:"l3"},[_vm._v("设为默认地址")])])]),_c('div',{staticClass:"has"},[_c('p',{staticClass:"l1"},[_c('span',{staticClass:"s1"},[_vm._v("公司")]),_c('span',[_vm._v("蔡菜菜(先生)")])]),_c('p',{staticClass:"l2"},[_vm._v(" 河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市 二七区河南省 郑州市 二七区 大学路80号华城国际中心19层 150****2232 ")]),_c('p',{staticClass:"l3"},[_vm._v("设为默认地址")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"no-add"},[_c('p',[_vm._v("您还没有自提点信息，你可以"),_c('span',[_vm._v("新增收货地址")])]),_c('img',{attrs:{"src":require("@/assets/images/noadd.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hea"},[_c('div',{staticClass:"h"},[_vm._v("配件图片")]),_c('div',{staticClass:"h hb"},[_vm._v("商品信息")]),_c('div',{staticClass:"h"},[_vm._v("单价")]),_c('div',{staticClass:"h"},[_vm._v("数量")]),_c('div',{staticClass:"h"},[_vm._v("小计")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"table"},[_c('div',{staticClass:"h"},[_c('img',{attrs:{"src":require("@/assets/images/noadd.png"),"alt":""}})]),_c('div',{staticClass:"h hb"},[_c('p',{staticClass:"li1"},[_vm._v(" 【2023新品英特尔酷睿i7笔记本】轻薄便携笔记本电脑15.6英寸办公【2023新品英特尔酷睿i7笔记本】轻薄便携笔记本电脑15.6英寸办公【2023新品英特尔酷睿i7笔记本】轻薄便携笔记本电脑15.6英寸办公 ")]),_c('p',{staticClass:"li2"},[_vm._v("这是配件品牌")])]),_c('div',{staticClass:"h"},[_vm._v("￥0.9620")]),_c('div',{staticClass:"h"},[_vm._v("100.00")]),_c('div',{staticClass:"h red"},[_vm._v("￥96.2000")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"msg"},[_c('p',[_vm._v(" 2件商品 "),_c('span',{staticClass:"m1"},[_vm._v("商品金额：")]),_c('span',{staticClass:"m2"},[_vm._v("￥207.40 ")])])])
}]

export { render, staticRenderFns }